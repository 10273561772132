import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../components/header';
import Footer from '../components/footer';
import Banner from '../components/home/banner';
import StoreManage from '../components/home/storeManage';
import EntityStores from '../components/home/entityStores';
import ProductAdvantages from '../components/home/productAdvantages';
import ClassicCase from '../components/home/classicCase';
import UseComparison from '../components/home/useComparison';
import Partners from '../components/home/partners';
import SEO from '../components/seo';

export default () => {
    useEffect(() => {
        // judgeGoMobile();
    }, []);
    
    const data = useStaticQuery(graphql`
        query {
            companys: allFile(
                filter: { relativePath: { regex: "images/home/companys-logo/.*/" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 172) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            partner: allFile(
                filter: { relativePath: { regex: "images/home/partner/.*/" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 172) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            productType: allFile(
                filter: { relativePath: { regex: "images/home/product-type-logo/.*/" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 184) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                    name
                }
            },
            example: allFile(
              filter: { relativePath: { regex: "images/home/example/.*/" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    `);

    const style = {
      titleStyle: { marginTop: '20px', fontSize: '30px' },
      descriptionStyle: { marginBottom: '40px', fontSize: '20px', fontWeight: 'normal'  }
    }

    return (
        <>
            <SEO title="首页" />
            <Header />
            <Banner />
            <StoreManage
                siteTitle='线上线下店铺管理 · 全面升级'
                siteDescription='功能强大全面，注册即可准确掌握线上线下店铺的整体运营情况'
                titleStyle={style.titleStyle}
                descriptionStyle={style.descriptionStyle}
            />
            <EntityStores
                siteTitle='面向 · 线下实体门店'
                siteDescription='帮助传统零售在新零售大背景下实现转型升级，让企业具备线上、线下 一体化经营的能力并通过各业务环节的融合，可达到数据化运营的目标'
                siteDataList={data.productType}
                titleStyle={style.titleStyle}
                descriptionStyle={style.descriptionStyle}
            />
            <ProductAdvantages
                siteTitle='实力制作 · 为你护航'
                siteDescription='专业的产品，过硬的技术实力，是您经营过程中的得力保障'
                titleStyle={style.titleStyle}
                descriptionStyle={style.descriptionStyle}
            />
            <ClassicCase
                siteTitle='经典案例 · 来自用户的心声'
                siteDescription='我们用专业的人做专业的事，专业的店货通ERP管理，更好的提升商家效率'
                siteDataList={data.example}
                titleStyle={style.titleStyle}
                descriptionStyle={style.descriptionStyle}
            />
            <UseComparison
                siteTitle='店货通 · 使用前后对比'
                siteDescription='提升业务效率，从选择一款合适的产品开始'
                titleStyle={style.titleStyle}
                descriptionStyle={style.descriptionStyle}
            />
            <Partners
                siteTitle='合作伙伴 · 感谢有你们'
                siteDescription='十多年以来，已对接全网超过50000+家的企业选择了我们'
                siteDataList={data.partner}
                titleStyle={style.titleStyle}
                descriptionStyle={style.descriptionStyle}
            />
            <Footer />
        </>
    );
};
