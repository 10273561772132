
import homeStyles from '../../styles/home.module.less';
import PropTypes from "prop-types"
import React from "react"
import Content from '../content';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Img from 'gatsby-image';


const Partners = ({ siteTitle, siteDescription, siteDataList, titleStyle, descriptionStyle}) => (
    <div style={{ background: '#fff' }}>
        <Content
                title={siteTitle}
                description={siteDescription}
                titleStyle={titleStyle}
                descriptionStyle={descriptionStyle}
            >
                <Row gutter={[0, 35]}>
                    {siteDataList.nodes.map((item, index) => (
                        <Col key={index} span={4}>
                        <Img className={homeStyles.imgAnimation} fixed={item.childImageSharp.fixed} />
                        </Col>
                    ))}
                    <Col span={4} className={homeStyles.partner__ellipsis}>
                      ...
                    </Col>
                    
                </Row>
        </Content>
    </div>
)

Partners.propTypes = {
    siteTitle: PropTypes.string,
    siteDescription: PropTypes.string,
    siteDataList: PropTypes.object,
    titleStyle: PropTypes.object,
    descriptionStyle: PropTypes.object
}

Partners.defaultProps = {
    siteTitle: ``,
    siteDescription: ``,
}


export default Partners
