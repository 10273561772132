
import homeStyles from '../../styles/home.module.less';
import PropTypes from "prop-types"
import React from "react"
import Content from '../content';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Img from 'gatsby-image';

const entityStores = ({ siteTitle, siteDescription, siteDataList,titleStyle, descriptionStyle }) => (
    <div style={{ background: '#c1d9f1' }}>
        <Content
            title={siteTitle}
            titleStyle={titleStyle}
            descriptionStyle={{ width: '770px', margin: 'auto', ...descriptionStyle }}
            description={siteDescription}
        >
            <Row className={homeStyles.entityStoresContainer}>
                {siteDataList.nodes.map((item, index) => (
                    <Col className={homeStyles.entityStores__item} key={index}>
                        <Img className={homeStyles.entityStores__img} title={item.name.split('.')[1]} fixed={item.childImageSharp.fixed} />
                        <div className={homeStyles.entityStores__text}>{item.name.split('.')[1]}</div>
                    </Col>
                ))}
            </Row>
        </Content>
    </div>

)

entityStores.propTypes = {
    siteTitle: PropTypes.string,
    siteDescription: PropTypes.string,
    siteDataList: PropTypes.object,
    titleStyle: PropTypes.object,
    descriptionStyle: PropTypes.object
}

entityStores.defaultProps = {
    siteTitle: ``,
    siteDescription: ``,
}

export default entityStores
