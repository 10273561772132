import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Swiper from 'react-id-swiper';
import Button from 'antd/es/button';
import ExperienceModal from '../../components/modal';
import 'swiper/css/swiper.css';
import bannerStyles from '../../styles/banner.module.less';
import HomeData from '../../store/home';

export default () => {
    const [showModal, setShowModal] = useState(false);
    const [swiper, updateSwiper] = useState(null);

    const data = useStaticQuery(graphql`
        query {
            swiper: allFile(
                filter: { relativePath: { regex: "images/home/cover(\\\\d+).jpg$/" } }
            ) {
                nodes {
                    childImageSharp {
                        fluid(quality: 80, maxHeight: 556) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `);
    // console.log(data);
    // data.forEach(item => {})
    const params = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        getSwiper: updateSwiper,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        spaceBetween: 30,
        containerClass: 'swiper-container home-banner'
    };
    const handleCancel = () => {
        setShowModal(false);
    };
    // 解决swiper 循环一轮后图片模糊的问题
    useEffect(() => {
        if (swiper) {
            const slides = swiper.slides;

            Object.values(slides).forEach((elem) => {
                if (elem.querySelector && elem.classList.contains('swiper-slide-duplicate')) {
                    elem.querySelector('picture > img').style.opacity = 1;
                }
            });
        }
    }, [swiper]);
    return (
        <>
            <Swiper {...params}>
                {data.swiper.nodes.map((item, index) => (
                    <div key={index}>
                        <Img fluid={item.childImageSharp.fluid} alt="" />
                        <div className={bannerStyles.homeBannerContent}>
                            <div>
                                <h3>{HomeData.bannerTextList[index].title}</h3>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: HomeData.bannerTextList[index].description
                                    }}
                                ></p>
                                <br />
                                <Button
                                    onClick={() => setShowModal(true)}
                                    size="large"
                                    type="danger"
                                >
                                    免费试用
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </Swiper>
            <ExperienceModal showModal={showModal} onCancel={handleCancel} />
        </>
    );
};
