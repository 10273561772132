
import homeStyles from '../../styles/home.module.less';
import PropTypes from "prop-types"
import React from "react"
import Content from '../content';
import Icon from '../icon';


const UseComparison = ({ siteTitle, siteDescription, titleStyle, descriptionStyle }) => (
    <div style={{ background: '#fff' }}>
        <Content
            title={siteTitle}
            description={siteDescription}
            titleStyle={titleStyle}
            descriptionStyle={descriptionStyle}
        >
            <ul className={homeStyles.comparison}>
                <li className={homeStyles.comparisonItem}>
                    <p>门店缺少互联网基因，缺乏线上线下的关联</p>
                    <div className={homeStyles.comparisonItem__center}>
                        <Icon
                            type="iconyoujiantou_huaban"
                        />
                    </div>

                    <p>通过使用店货通，打通商城和线下实体门店，加强O2O对门店的融合</p>
                </li>
                <li className={homeStyles.comparisonItem}>
                    <p>企业管理不规范，各层级分工难以精细化</p>
                    <div className={homeStyles.comparisonItem__center}>使</div>
                    <p>采购和销售数据、员工、库 存、线上线下分店、权限管理、财务管理这些统统搞定</p>
                </li>
                <li className={homeStyles.comparisonItem}>
                    <p>商品库存需登录后台界面核销订单，无法做到数据实时更新，繁琐效率低</p>
                    <div className={homeStyles.comparisonItem__center}>用</div>
                    <p>仓库作为连接线上线下的纽带，是线上线下销售订单至关重要的环节</p>
                </li>
                <li className={homeStyles.comparisonItem}>
                    <p>成本把控差、毛利率不清晰、数据统计难、账目混乱，也无法实现线上线下同步</p>
                    <div className={homeStyles.comparisonItem__center}>后</div>
                    <p>财务报表统一查询和核对，财务数据自动汇总、清晰明了，节省人力和时间</p>
                </li>
                <li className={homeStyles.comparisonItem}>
                    <p>对线上线下的经营及人群消费，缺乏数据汇总和分析</p>
                    <div className={homeStyles.comparisonItem__center}>&nbsp;</div>
                    <p>对客户的消费明细清楚明了，统计分析有迹可循</p>
                </li>
            </ul>
        </Content>
    </div>
)

UseComparison.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object
}

UseComparison.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default UseComparison
