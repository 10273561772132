
import React, { useState } from 'react';
import Content from '../../components/content';
import PropTypes from "prop-types"
import Img from 'gatsby-image';

import homeStyles from '../../styles/home.module.less';
import Swiper from 'react-id-swiper';

import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from '../../components/icon';
import HomeData from '../../store/home'



const ClassicCases = ({ siteTitle, siteDescription, siteDataList, titleStyle, descriptionStyle }) => {
    const [swiper, updateSwiper] = useState(null);
    const [active, setActive] = useState(0);

    const siteIconList = HomeData.classicCase;
    const slideTo = (index) => {
        if (swiper !== null) {
            swiper.slideTo(index);
            setActive(index);
        }
    };

    const params = {
        containerClass: 'swiper-container home--banner__page4',
        noSwiping: true,
        getSwiper: swiper => updateSwiper(swiper)
    };

    return (
        <div className={homeStyles.case__wrap}>
            <Content
                title={siteTitle}
                description={siteDescription}
                titleStyle={titleStyle}
                descriptionStyle={descriptionStyle}
            >
                <div>
                    <Row gutter={[32, 32]} className={homeStyles.classicCaseTabs__container}>
                        {siteIconList.map((item, index) => (
                            <Col className={homeStyles.classicCaseTabs__item} key={index} div={4} onClick={() => slideTo(index)} >
                                <Icon className={homeStyles.classicCaseTabs__icon} style={{ color: active === index ? '#43aaf9' : '#fff' }} type={item.icon} />
                                <strong className={homeStyles.classicCaseTabs__title} style={{ color: active === index ? '#43aaf9' : '#fff' }} >{item.title}</strong>
                            </Col>
                        ))}
                    </Row>

                    <Swiper {...params}>
                        {siteDataList.nodes.map((item, index) => (
                            <div key={index} className={homeStyles.classicCaseContent}>
                                <Img fixed={item.childImageSharp.fixed} />
                                <div className={homeStyles.classicCaseContent__article}>
                                    <>
                                        <div className={homeStyles.classicCaseContent__title}>{siteIconList[index].articleTitle}</div>
                                        <div className={homeStyles.classicCaseContent__text}>{siteIconList[index].articleContent}</div>
                                    </>
                                    <>
                                        <div className={homeStyles.classicCaseContent__title}>核心价值</div>
                                        <ol className={homeStyles.classicCaseContent__ol}>
                                            {siteIconList[index].coreList.map((item, index) => (
                                                <li className={homeStyles.classicCaseContent__li} key={index}> {item}</li>
                                            ))}
                                        </ol>
                                    </>
                                </div>
                            </div>
                        ))}
                    </Swiper>
                </div>
            </Content>
        </div>
    );
};

ClassicCases.propTypes = {
    siteTitle: PropTypes.string,
    siteDescription: PropTypes.string,
    siteDataList: PropTypes.object,
    titleStyle: PropTypes.object,
    descriptionStyle: PropTypes.object
}

ClassicCases.defaultProps = {
    siteTitle: ``,
    siteDescription: ``,
}

export default ClassicCases;
