
import homeStyles from '../../styles/home.module.less';
import PropTypes from "prop-types"
import React from "react"
import Content from '../content';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from '../icon';
import HomeData from '../../store/home'

const storeManage = ({ siteTitle, siteDescription, titleStyle, descriptionStyle }) => (
    <Content
        title={siteTitle}
        titleStyle={titleStyle}
        descriptionStyle={descriptionStyle}
        description={siteDescription}
    >
        <Row gutter={[0, 42]} className={homeStyles.storeContainer} >
            {HomeData.storeList.map((item, index) => (
                <Col key={index} span={8}>
                    <div className={homeStyles.fullUpgradeItem}>
                        <Icon type={item.icon} style={{ color: '#4e74ef' }} />
                        <strong>{item.title}</strong>
                        <p>{item.content}</p>
                    </div>
                </Col>
            ))}
        </Row>
    </Content>
)

storeManage.propTypes = {
    siteTitle: PropTypes.string,
    siteDescription: PropTypes.string,
    titleStyle: PropTypes.object,
    descriptionStyle: PropTypes.object
}

storeManage.defaultProps = {
    siteTitle: ``,
    siteDescription: ``,
}

export default storeManage
