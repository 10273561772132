
import homeStyles from '../../styles/home.module.less';
import PropTypes from "prop-types"
import React from "react"
import Content from '../content';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from '../icon';
import HomeData from '../../store/home'


const productAdvantages = ({ siteTitle, siteDescription, titleStyle, descriptionStyle }) => (
    <div style={{ background: '#fff' }}>
        <Content
            title={siteTitle}
            titleStyle={titleStyle}
            description={siteDescription}
            descriptionStyle={descriptionStyle}
        >
            <Row gutter={[16, 60]}>
                {HomeData.productAdvantageList.map((item, index) => (
                    <Col key={index} span={8}>
                        <div className={homeStyles.safeguardItem}>
                            <Icon
                                className={homeStyles.safeguardItem__icon}
                                type={item.icon}
                                style={{ backgroundImage: item.backgroundImage }}
                            />
                            <div className={homeStyles.safeguardItem__title}>{item.title}</div>
                            <div className={homeStyles.safeguardItem__description}>
                                <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </Content>
    </div>
)

productAdvantages.propTypes = {
    siteTitle: PropTypes.string,
    siteDescription: PropTypes.string,
    titleStyle: PropTypes.object,
    descriptionStyle: PropTypes.object
}

productAdvantages.defaultProps = {
    siteTitle: ``,
    siteDescription: ``,
}


export default productAdvantages
